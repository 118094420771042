<template>
    <section>
        <div class="overflow-auto custom-scroll" :style="`width:100%;height:calc(100vh${alto2?'':' - 184px'});`">
            <div class="row mx-0 pt-4">
                <div class="col px-0 mr-2">
                    <div class="row mx-0 align-items-center bg-white br-t-12 pt-2 pr-2">
                        <div class="col pr-0 d-middle">
                            <estrellas :row="tienda" />
                            <p class="f-22 f-600 text-general">
                                {{ tienda.propietario_nombre }}
                            </p>
                        </div>
                        <div v-if="$can('boton_leecheros_editar_datos')" class="btn-action border" @click="editarDatos">
                            <i class="icon-pencil-outline f-18" />
                        </div>
                        <!-- <div class="col-auto px-0 d-middle justify-content-end"> -->
                        <!-- <chatIconoChat :id-tienda="parseInt(this.$route.params.id_tienda)" /> -->
                        <!-- </div> -->
                    </div>
                    <div class="row mx-0 mb-3 pt-2 pb-3 px-5 bg-white br-b-12">
                        <img :src="tienda.logo_firmado" class="br-12 border obj-cover" style="width:162px;height:162px;" />
                        <div class="col">
                            <p class="col-12 text-general f-15">
                                <strong> Fecha nacimiento: </strong>
                                {{ tienda.propietario_nacimiento }}
                            </p>
                            <p class="col-12 text-general f-15">
                                <strong> Genero: </strong>
                                {{ tienda.genero_nombre }}
                            </p>
                            <div class="col-12 px-0">
                                <div class="row mx-0 px-2 my-2">
                                    <div class="f-16 py-2 px-3 d-middle br-8 bg-fondo border text-general" style="min-width:200px;max-width:400px;height:32px;">
                                        <i class="icon-warehouse f-20" />
                                        <p class="tres-puntos ml-2">
                                            <strong>CEDIS:</strong> {{ tienda.nombre_cedis }}
                                        </p>
                                    </div>
                                    <el-tooltip v-if="$can('botones_leecheros_gestionar_cedis')" placement="bottom" content="Cambiar Cedis" effect="light">
                                        <div class="btn-action border cr-pointer br-8 d-middle-center text-general ml-2">
                                            <i v-if="$can('botones_leecheros_gestionar_cedis')" class="icon-pencil-outline f-20 cr-pointer ml-1" @click="cambiarCedis" />
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div v-if="$config.menu.creditos" class="col-12 px-0">
                                <div class="row mx-0 px-2 my-2">
                                    <div class="f-16 py-2 px-3 d-middle br-8 bg-fondo border text-general" style="min-width:200px;max-width:320px;height:32px;">
                                        <i class="icon-creditos" />
                                        <p class="tres-puntos ml-3">
                                            <strong>Cupo del crédito:</strong> {{ tienda.cupo_credito == 0 ? 'Ilimitado' : separadorNumeroDecimales(tienda.cupo_credito) }}
                                        </p>
                                    </div>
                                    <el-tooltip placement="bottom" content="Editar cupo" effect="light">
                                        <div class="btn-action border cr-pointer br-8 d-middle-center text-general ml-2" @click="irEditarCupo">
                                            <i class="icon-pencil-outline f-20 cr-pointer ml-1" />
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip placement="bottom" content="Ver historial" effect="light">
                                        <div class="btn-action border cr-pointer br-8 d-middle-center text-general ml-2" @click="verHistorial">
                                            <i class="icon-historial f-20 cr-pointer ml-1" />
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="col-12 px-0">
                                <div class="row mx-0 px-2 my-2">
                                    <div class="f-16 py-2 px-3 d-middle br-8 bg-fondo border text-general" style="min-width:300px;max-width:400px;height:32px;">
                                        <i class="icon-playlist-check f-22" />
                                        <p class="tres-puntos ml-2">
                                            <strong>Comisión por condición:</strong> {{ tienda.nombre_condicion }}
                                        </p>
                                    </div>
                                    <el-tooltip placement="bottom" content="Editar cupo" effect="light">
                                        <div class="btn-action border cr-pointer br-8 d-middle-center text-general ml-2" @click="editarComision">
                                            <i class="icon-pencil-outline f-20 cr-pointer ml-1" />
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                        <div v-if="tienda.descuento_lista && tienda.descuento_lista != 0 && tienda.categoria_lista" class="col-2 text-center mt-5">
                            <img :src="tienda.categoria_lista_imagen" class="rounded-circle border-category" width="55" height="55" />
                            <p class="text-general f-14"><b>{{ tienda.categoria_lista }}</b></p>
                            <p class="text-general f-14">Descuento de lista</p>
                            <p v-if="tienda.descuento_lista === 1" class="text-general f-14">(Automático)</p>
                            <p v-if="tienda.descuento_lista === 2" class="text-general f-14">(Manual)</p>
                        </div>
                    </div>
                    <div class="row mx-0 px-0 mt-3 bg-white br-12 py-2">
                        <div class="col-12">
                            <p class="text-general text-center f-15">
                                <i class="icon-map-marker-outline text-general f-18" />
                                {{ tienda.direccion || 'No registra' }}
                            </p>
                            <div class="row mx-0 my-3 justify-center">
                                <div class="col-auto text-general">
                                    <strong> Complemento: </strong>
                                    {{ _.defaultTo(tienda.direccion_anexo,'No registra') }}
                                </div>
                                <!-- <div class="col-auto text-general">
                                    <b>Barrio / Colonia: </b> No registra
                                </div> -->
                                <div v-if="tienda.cobertura" class="col-auto text-general">
                                    <b>Cobertura:</b>
                                    {{ agregarSeparadoresNumero(tienda.cobertura) }} metros
                                </div>
                                <div v-else class="col-auto text-general">
                                    <b>Cobertura:</b>
                                    Ilimitada
                                </div>
                            </div>
                            <!-- <p class="text-general mt-2">
                        <strong> {{ tienda.pais }}, </strong>
                        {{ tienda.departamento }},
                        {{ tienda.ciudad }}
                    </p> -->
                            <!-- <p class="text-general mt-2">
                        <strong> Dirección: </strong>
                    </p> -->
                        </div>
                        <div class="col-12 cr-pointer mb-3" @click="abrirModalMapa">
                            <mapa ref="mapaPeq" :coordenadas="coordenadas" />
                        </div>
                    </div>
                    <div class="row mx-0 mt-3 py-3 bg-white br-12">
                        <div class="pl-5 mb-4">
                            <div class="row mx 0 mt-3 align-items-center mb-5">
                                <p class="col-auto mr-5 px-0 f-17 f-500 text-general">
                                    Horario de atención
                                </p>
                                <el-tooltip placement="bottom" content="Editar horario de atención" effect="light">
                                    <div v-if="tienda.franjas.parametros" class="btn-action border cr-pointer br-8 d-middle-center text-general ml-2" @click="editarFranjas">
                                        <i class="icon-pencil-outline f-20 cr-pointer ml-1" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="row mx-0">
                                <div v-for="data in tienda.franjas.horarios" :key="data.id" class="col-12 pl-0 d-middle mb-3 mr-4">
                                    <div class="col-2">
                                        <span class="text-general f-600 f-16 mr-3">
                                            {{ data.dia }}
                                        </span>
                                    </div>
                                    <template v-if="data.horarios.length > 0">
                                        <div class="col">
                                            <p v-for="horario in data.horarios" :key="horario.id" class="text-general f-16 f-300">
                                                {{ horario.inicio }} - {{ horario.fin }}
                                            </p>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="col">
                                            <p> - </p>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto px-0 ml-2">
                    <div class="row mx-0 py-3 bg-white br-12">
                        <div class="col-12">
                            <p class="text-general mt-2">
                                <strong> Contacto </strong>
                            </p>
                            <p class="text-general mt-2">
                                <strong> Número de celular: </strong>
                                {{ tienda.telefono || 'No registra' }}
                            </p>
                            <p class="text-general mt-2">
                                <strong>Dirección de correo electrónico: </strong>
                                {{ tienda.correo || 'No registra' }}
                            </p>
                        </div>
                        <div class="col-12">
                            <div class="row mx-0 my-2">
                                <div class="col-auto pl-0 f-600 text-general">
                                    Descripción:
                                </div>
                                <div class="col mx-1 pl-0 text-general">
                                    {{ tienda.descripcion || 'No registra' }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 my-3 bg-white br-12 py-3">
                        <div class="col-12 my-2">
                            <p class="text-general">
                                <strong> Galería </strong>
                            </p>
                        </div>
                        <img v-for="(imagen,i) in tienda.imagenes" :key="i" :src="imagen.imagen_firmada" class="mx-1 my-1 br-12 border obj-cover" style="width:162px;height:162px;" />
                    </div>
                    <template>
                        <div class="row mx-0 mt-3 py-3 bg-white br-12">
                            <div class="col-12">
                                <div class="row mx-0">
                                    <div class="col-auto px-0">
                                        <i class="icon-storefront-outline text-general f-20" />
                                    </div>
                                    <div class="col">
                                        <p v-if="tienda.tipo == 2" class="text-general mt-2">
                                            <strong> Tienda: </strong>
                                            {{ tienda.nombre }}
                                        </p>
                                        <p class="text-general mt-2">
                                            <strong> N.I.T de la tienda: </strong>
                                            {{ _.defaultTo(tienda.nit,'No registra') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="tienda.nit_soporte" class="col-12 d-flex">
                                <div id="df_gbp" class="bg-general3 br-12 d-middle-center text-white px-5 cr-pointer" :class="file_type(tienda.nit_soporte) === 'application/pdf' ? '_df_custom' : ''" style="height:40px;width:380px;" @click="openAdjunto(tienda)">
                                    <i class="icon-doc-inv" />
                                    Adjunto: Soporte Nit
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!-- Modales -->
        <modal-editar-datos ref="modalEditarDatos" @actualizar="info_tienda" />
        <modal-cambiar-cedis ref="modalCambiarCedis" @actualizar="info_tienda" />
        <modal-editar-condicion ref="modalEditarCondicion" @actualizar="info_tienda" />
        <modal-imagen ref="modal_imagen" />
        <modal-mapa ref="modalMapa" />
        <modalHistorialCupo ref="modalHistorialCupo" />
        <modalEditarCupo ref="modalEditarCupo" @actualizar="info_tienda" />
        <modalHorarioAtencion ref="modalHorarioAtencion" @actualizar="info_tienda" />
        <!-- Modales -->
    </section>
</template>

<script>
import Produccion from "../../../../services/tiendas/produccion";
import CambioDatos from "~/services/tiendas/cambio_datos";

export default {
    components: {
        modalEditarDatos: () => import('../partials/modalCompletarRegistro'),
        modalCambiarCedis: () => import('../partials/modalCambiarCedis'),
        modalEditarCondicion: () => import('../partials/modalEditarCondicion'),
        modalMapa: () => import('../partials/modalMapa'),
        modalHistorialCupo: () => import('../partials/modalHistorialCupo'),
        modalEditarCupo: () => import('../partials/modalEditarCupo'),
        modalHorarioAtencion: () => import('../partials/modalHorarioAtencion'),
    },
    props: {
        alto2: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            tienda:{
                id: null,
                estado: null,
                nombre: null,
                logo: null,
                logo_mini: null,
                cliente_pedido_directo: null,
                cliente_entrega_directa: null,
                permiso_descuento: null,
                manejo_inventario: null,
                cambio_tipo_pedido: null,
                cambio_condiciones: null,
                descripcion: null,
                telefono_celular: null,
                idm_ciudad: null,
                barrio: null,
                cobertura: null,
                direccion: null,
                direccion_anexo: null,
                longitud: null,
                latitud: null,
                propietario_nombre: null,
                propietario_genero: null,
                propietario_nacimiento: null,
                nit: null,
                nit_soporte: null,
                created_at: null,
                created_by: null,
                updated_at: null,
                idm_estado: null,
                idm_pais: null,
                ciudad: null,
                departamento: null,
                estrella: null,
                id_estrella: null,
                id_cedis: null,
                id_condicion: null,
                id_direccion: null,
                logo_firmado: null,
                logo_mini_firmado: null,
                nit_soporte_firmado: null,
                nombre_cedis: '',
                nombre_condicion: '',
                imagenes:[],
                cupo_credito: null,
                franjas: [],
                descuento_lista: null,
                categoria_lista: null,
                categoria_lista_imagen: null,
                descuento_lista_clientes: null,
            },
            coordenadas: { lat: 0, lng: 0 },
        }
    },
    computed: {
        id_tienda(){
            return Number(this.$route.params.id_tienda)
        }
    },
    mounted(){
        this.info_tienda()
    },
    methods: {
        async info_tienda(){
            try {
                const { data } = await Produccion.info_tienda(this.$route.params.id_tienda)
                this.tienda = data.info
                this.coordenadas.lat = +this.tienda.latitud
                this.coordenadas.lng = +this.tienda.longitud
                console.log({ ...this.coordenadas})
                this.$refs.mapaPeq.setPosition()
                this.$store.commit('tiendas/info_cabecera/setParametros', this.tienda)
                await this.$store.dispatch('tiendas/info_cabecera/action_info_cabecera', this.$route.params.id_tienda)
            } catch (error){
                this.error_catch(error)
            }
        },
        verMapa(){
            console.log('primero');
        },
        editarDatos(){
            // this.tienda.tienda = this.tienda.nombre !== null ? true : false

            this.$refs.modalEditarDatos.toggle(this.tienda.idm_pais,this.tienda.idm_estado,this.tienda.id_direccion)
        },

        abrirModalMapa(){
            console.log({ ...this.coordenadas},'modal abrir')

            this.$refs.modalMapa.toggle()
        },
        openAdjunto(item){
            if(this.file_type(item.nit_soporte) == 'application/pdf'){
                this.set_pdf(item.nit_soporte_firmado)
            }else if(this.file_type(item.nit_soporte) == 'image/*'){
                this.$refs.modal_imagen.toggle(item.nit_soporte_firmado)
            }
        },
        actualizarCoordenadas({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
            this.$refs.mapaPeq.setPosition()

        },
        cambiarCedis(){
            console.log(this.tienda);
            this.$refs.modalCambiarCedis.toggle(this.tienda)
        },
        cambiarCondicion(){
            this.$refs.modalEditarCondicion.toggle(this.tienda)
        },
        async updateClientePedido(){
            try {
                const {data} = await CambioDatos.updateClientePedido(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updatePrecioSugerido(){
            try {
                const {data} = await CambioDatos.updatePrecioSugerido(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateEntregaDirecta(){
            try {
                const {data} = await CambioDatos.updateEntregaDirecta(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateManejoInventario(){
            try {
                const {data} = await CambioDatos.updateManejoInventario(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizaada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateCambioTipoPedido(){
            try {
                const {data} = await CambioDatos.updateCambioTipoPedido(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizaada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async updateCambioCondiciones(){
            try {
                const {data} = await CambioDatos.updateCambioCondiciones(this.id_tienda)
                this.notificacion('Mensaje', 'Informacion actualizaada', 'success')

            } catch (e){
                this.error_catch(e)
            }
        },
        verHistorial(){
            this.$refs.modalHistorialCupo.toggle();
        },
        irEditarCupo(){
            this.$refs.modalEditarCupo.toggle(this.tienda.cupo_credito);
        },
        editarFranjas(){
            this.$refs.modalHorarioAtencion.toggle(this.tienda.franjas);
        },
        editarComision(){
            const params = {
                id_condicion: this.tienda.id_condicion,
                id_cedis: this.tienda.id_cedis
            };
            this.$refs.modalEditarCondicion.toggle(params);
        }
    }
}
</script>

<style lang="css" >
.br-12{
    border-radius: 12px;
}
.border-category {
    border: 1px solid #00000029;
}
</style>
